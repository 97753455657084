jQuery(document).ready(function ($) {
  /* ==================== Fonts ==================== */
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["DM Sans:400,500,700", "Elza:400,500,700"],
    },
  });

  /* ==================== Menus ==================== */

  const mobileMenuToggle = document.querySelector(".navToggle"),
    mobileMenu = document.getElementById("mobileNav"),
    header = document.querySelector("#header .container"),
    subMenuItems = document.querySelectorAll(".menu-item-has-children"),
    mobileSubMenuItems = document.querySelectorAll(
      "#menu-main-menu .menu-item-has-children"
    ),
    adminBar = document.getElementById("wpadminbar"),
    headerOuter = document.querySelector("#header");

  window.addEventListener("scroll", () => {
    if (window.scrollY > 40) {
      headerOuter.classList.add("scrolled");
    } else {
      headerOuter.classList.remove("scrolled");
    }
  });

  mobileSubMenuItems.forEach((element) => {
    element.addEventListener("click", toggleSubMenu);
  });

  function toggleSubMenu() {
    this.classList.toggle("active");
  }

  // const setHeaderHeight = () => {
  //   let headerOffset = header.getBoundingClientRect().bottom;
  //   let adminBarHeight;
  //   if (adminBar) {
  //     adminBarHeight = adminBar.offsetHeight;
  //   } else {
  //     adminBarHeight = 0;
  //   }
  //   if (headerOffset && adminBarHeight !== null) {
  //     mobileMenu.style.top = `${headerOffset + adminBarHeight + 20}px`;
  //     mobileMenu.style.minHeight = `calc(100vh - ${headerOffset}px)`;
  //   }
  // };

  // Initialize header and mobile menu height
  // setHeaderHeight();

  // Listen for window resizing to update header and mobile menu height as screen size changes
  // addEventListener("resize", (event) => {
  //   setHeaderHeight();
  // });

  mobileMenuToggle.addEventListener("click", () => {
    mobileMenuToggle.classList.toggle("is-active");
    mobileMenu.classList.toggle("menu-open");
  });

  /* ==================== Splash ==================== */
  const splashClose = document.getElementById("splash-close"),
    splash = document.querySelector(".splash");

  let splashClosed = sessionStorage.getItem("closedSplash");
  if (!splashClosed) {
    splash.classList.remove("d-none");
  }

  if (splashClose) {
    splashClose.addEventListener("click", () => {
      splash.classList.add("d-none");
      sessionStorage.setItem("closedSplash", true);
    });
  }
});
